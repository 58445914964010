import {
  type Form,
  type SubmitOptions,
  useActionData,
  useFetcher,
  useNavigation,
  useSubmit
} from '@remix-run/react'
import {
  type ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import type { ZodObject, ZodRawShape, z } from 'zod'
import type { MutationResult } from '~/utils/mutation.server'

type GenericSchemaWithIntent<T extends ZodRawShape> = ZodObject<
  T & { intent: z.ZodLiteral<string> }
>

type UseFormProps<
  T extends ZodRawShape,
  U extends GenericSchemaWithIntent<T>
> = {
  schema: U
  onSuccess?: () => void
  include?: Partial<z.infer<U>>
  defaultValues?: Partial<z.infer<U>>
} & SubmitOptions

export const useForm = <
  T extends ZodRawShape,
  U extends GenericSchemaWithIntent<T>
>({
  schema,
  fetcherKey: explicitFetcherKey,
  method = 'POST',
  action,
  navigate = false,
  onSuccess,
  include,
  defaultValues
}: UseFormProps<T, U>) => {
  const { value: intent } = schema.shape.intent
  const [errors, setErrors] = useState<z.inferFlattenedErrors<any>>()
  const formRef = useRef<HTMLFormElement>(null)
  const navigation = useNavigation()
  const submit = useSubmit()
  const actionData = useActionData<MutationResult<any>>()
  const fetcherKey = explicitFetcherKey || intent
  const fetcher = useFetcher<MutationResult<any>>({ key: fetcherKey })
  const result = navigate ? actionData : fetcher?.data

  const isSubmitting = navigate
    ? navigation.state !== 'idle' &&
      navigation.formData?.get('intent') === intent
    : fetcher?.state !== 'idle'

  const memoizedDefaultValues = useMemo(() => {
    if (!defaultValues) return undefined
    return Object.fromEntries(
      Object.entries(defaultValues).map(([key, value]) => [
        key,
        String(value || '')
      ])
    ) as { [key in keyof z.infer<U>]: string }
  }, [defaultValues])

  const getFormProps = useCallback((): ComponentProps<typeof Form> => {
    return {
      ref: formRef,
      onSubmit: (e) => {
        e.preventDefault()
        handleSubmit()
      }
    }
  }, [])

  const schemaKeys = useMemo(() => {
    const keys = [...schema.keyof().options] as Array<keyof z.infer<U>>
    return keys.reduce(
      (acc, key) => {
        acc[key] = key
        return acc
      },
      {} as Record<keyof z.infer<U>, keyof z.infer<U>>
    )
  }, [schema])

  const handleSubmit = useCallback(
    (additionalData?: Record<string, any>) => {
      setErrors(undefined)

      const formElement = formRef.current
      const formData = new FormData(formElement ?? undefined)

      if (additionalData) {
        for (const [key, value] of Object.entries(additionalData)) {
          if (value === undefined || value === null) continue
          formData.set(key, value)
        }
      }

      const validationResult = schema.safeParse(
        Object.fromEntries(formData.entries())
      )

      if (!validationResult.success) {
        setErrors(validationResult.error.flatten())
        return
      }

      submit(formData, { method, action, navigate, fetcherKey })
    },
    [submit, navigate, method, action, fetcherKey, schema]
  )

  const resetForm = useCallback(() => {
    formRef.current?.reset()
    setErrors(undefined)
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  useEffect(() => {
    if (result?.ok) {
      setErrors(undefined)
      onSuccess?.()
    }

    if (!result?.ok && result?.errors) {
      setErrors(result.errors)
    }
  }, [result])

  return {
    keys: schemaKeys,
    config: {
      method,
      navigate,
      intent,
      fetcherKey
    },
    defaultValues: memoizedDefaultValues,
    getFormProps,
    state: { isSubmitting },
    reset: resetForm,
    submit: handleSubmit,
    data: result,
    include,
    errors
  }
}
