import { Form as RemixForm } from '@remix-run/react'
import { Button } from '@repo/ui/components/Button.js'
import { Checkbox } from '@repo/ui/components/Checkbox.js'
import { Input } from '@repo/ui/components/Input.js'
import { Label } from '@repo/ui/components/Label.js'
import { RadioGroup } from '@repo/ui/components/RadioGroup.js'
import { Select } from '@repo/ui/components/Select.js'
import { Textarea } from '@repo/ui/components/Textarea.js'
import { cn } from '@repo/ui/utils/utils'
import {
  type ComponentProps,
  type PropsWithChildren,
  createContext,
  useContext
} from 'react'
import type { useForm } from '~/hooks/useForm'

type FormProps = ReturnType<typeof useForm> & { className?: string }

type FormContextType = Pick<
  FormProps,
  'submit' | 'data' | 'state' | 'defaultValues' | 'errors'
>

const FormContext = createContext<FormContextType | undefined>(undefined)

export const useFormContext = () => {
  const context = useContext(FormContext)
  if (!context) throw new Error('useFormContext must be used within <Form />')
  return context
}

export const Form = ({
  children,
  className,
  ...props
}: PropsWithChildren<FormProps>) => {
  const { getFormProps, config, include } = props

  const { key, ...formProps } = getFormProps()

  return (
    <FormContext.Provider
      value={{
        submit: props.submit,
        data: props.data,
        state: props.state,
        defaultValues: props.defaultValues,
        errors: props.errors
      }}
    >
      <RemixForm {...formProps} className={cn(className)}>
        <input type="hidden" name="intent" value={config.intent} />
        {Object.entries(include ?? {}).map(([key, value]) => {
          if (value === undefined || value === null) return null
          return (
            <input key={key} type="hidden" name={key} value={String(value)} />
          )
        })}
        {children}
      </RemixForm>
    </FormContext.Provider>
  )
}

type FormFieldContextType = { name: string }

const FormFieldContext = createContext<FormFieldContextType | undefined>(
  undefined
)

export const useFormField = () => {
  const context = useContext(FormFieldContext)
  if (!context)
    throw new Error('useFormField must be used within <FormField />')
  return context
}

export const FormField = ({
  name,
  className,
  children,
  ...props
}: ComponentProps<'fieldset'> & { name: string }) => {
  return (
    <FormFieldContext.Provider value={{ name }}>
      <fieldset className={cn('space-y-2', className)} {...props}>
        {children}
      </fieldset>
    </FormFieldContext.Provider>
  )
}

export const FormFieldLabel = ({
  className,
  ...props
}: ComponentProps<typeof Label>) => {
  const { name } = useFormField()
  return <Label htmlFor={name} {...props} className={cn(className)} />
}

export const FormFieldHint = ({
  className,
  ...props
}: ComponentProps<'small'>) => {
  return (
    <small
      className={cn('text-muted-foreground text-xs block', className)}
      {...props}
    />
  )
}

const useDefaultValue = (name: string) => {
  const { defaultValues } = useFormContext()
  const value = defaultValues?.[name]
  return value ? String(value) : undefined
}

export const FormInput = ({
  defaultValue: defaultValueProp,
  ...props
}: ComponentProps<typeof Input>) => {
  const { name } = useFormField()
  const providedDefaultValue = useDefaultValue(name)
  const defaultValue = props.value ? undefined : providedDefaultValue

  return (
    <Input
      name={name}
      autoComplete="off"
      {...props}
      defaultValue={defaultValue ?? defaultValueProp}
    />
  )
}

export const FormTextarea = ({
  defaultValue: defaultValueProp,
  ...props
}: ComponentProps<typeof Textarea>) => {
  const { name } = useFormField()
  const defaultValue = useDefaultValue(name)

  return (
    <Textarea
      name={name}
      autoComplete="off"
      {...props}
      defaultValue={defaultValue ?? defaultValueProp}
    />
  )
}

export const FormSelect = ({
  defaultValue: defaultValueProp,
  ...props
}: ComponentProps<typeof Select>) => {
  const { name } = useFormField()
  const defaultValue = useDefaultValue(name)

  return (
    <Select
      name={name}
      {...props}
      defaultValue={defaultValue ?? defaultValueProp}
    />
  )
}

export const FormCheckbox = ({
  defaultValue: defaultValueProp,
  ...props
}: ComponentProps<typeof Checkbox>) => {
  const { name } = useFormField()
  const defaultValue = useDefaultValue(name)

  return (
    <Checkbox
      name={name}
      {...props}
      defaultValue={defaultValue ?? defaultValueProp}
    />
  )
}

export const FormRadioGroup = ({
  defaultValue: defaultValueProp,
  ...props
}: ComponentProps<typeof RadioGroup>) => {
  const { name } = useFormField()
  const defaultValue = useDefaultValue(name)

  return (
    <RadioGroup
      name={name}
      {...props}
      defaultValue={defaultValue ?? defaultValueProp}
    />
  )
}

export const FormSubmitButton = ({
  className,
  ...props
}: ComponentProps<typeof Button>) => {
  const { state, submit } = useFormContext()

  return (
    <Button
      loading={state.isSubmitting}
      className={cn(className)}
      type="submit"
      onClick={(e) => {
        e.preventDefault()
        submit()
      }}
      {...props}
    />
  )
}

const ErrorMessage = ({ className, ...props }: ComponentProps<'span'>) => {
  return (
    <span
      className={cn(
        'text-destructive bg-rose-100 py-1 px-2 rounded-sm text-xs font-medium w-fit block',
        className
      )}
      {...props}
    />
  )
}

export const FormFieldError = ({ ...props }: ComponentProps<'span'>) => {
  const { name } = useFormField()
  const { errors } = useFormContext()

  if (!errors) return null

  const fieldErrors = errors?.fieldErrors?.[name]

  if (!fieldErrors) return null

  return (
    <div className="space-y-2">
      {fieldErrors.map((error, i) => (
        <ErrorMessage key={String(i)} {...props}>
          {error}
        </ErrorMessage>
      ))}
    </div>
  )
}

export const FormError = ({ ...props }: ComponentProps<'span'>) => {
  const { data } = useFormContext()

  if (!data) return null
  if (data?.ok) return null

  if (!data.errors.formErrors.length) return null

  return (
    <div className="space-y-2">
      {data.errors.formErrors.map((error, i) => (
        <ErrorMessage key={String(i)} {...props}>
          {error}
        </ErrorMessage>
      ))}
    </div>
  )
}
